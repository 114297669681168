import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
      meta: { title: '首页', keywords: '', description: '' }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue'),
      meta: { title: '公司介绍', keywords: '', description: '' }
    },
    {
      path: '/culture',
      name: 'culture',
      component: () => import('./views/Culture.vue'),
      meta: { title: '企业文化', keywords: '', description: '' }
    },
    {
      path: '/process',
      name: 'process',
      component: () => import('./views/Process.vue'),
      meta: { title: '发展历程', keywords: '', description: '' }
    },
    {
      path: '/news/:type(\\d+)?',
      name: 'news',
      component: () => import('./views/News.vue'),
      meta: { title: '新闻', keywords: '', description: '' }
    },
    {
      path: '/new/:id(\\d+)',
      name: 'newsDetail',
      component: () => import('./views/New.vue'),
      meta: { title: '新闻详情', keywords: '', description: '' }
    },
    {
      path: '/products/:pid(\\d+)',
      name: 'products',
      component: () => import('./views/Products.vue'),
      meta: { title: '产品', keywords: '', description: '' }
    },
    {
      path: '/products',
      name: 'products',
      component: () => import('./views/Products2.vue'),
      meta: { title: '产品', keywords: '', description: '' }
    },
    {
      path: '/product/:id(\\d+)',
      name: 'productDetail',
      component: () => import('./views/ProductDetail.vue'),
      meta: { title: '产品', keywords: '', description: '' }
    },
    {
      path: '/cases/:pid(\\d+)',
      name: 'cases',
      component: () => import('./views/Cases.vue'),
      meta: { title: '案例', keywords: '', description: '' }
    },
    {
      path: '/cases',
      name: 'cases',
      component: () => import('./views/Cases2.vue'),
      meta: { title: '案例', keywords: '', description: '' }
    },
    {
      path: '/case/:id(\\d+)',
      name: 'caseDetail',
      component: () => import('./views/CaseDetail.vue'),
      meta: { title: '案例', keywords: '', description: '' }
    },
    {
      path: '/fields/:pid(\\d+)?',
      name: 'fields',
      component: () => import('./views/Fields.vue'),
      meta: { title: '修复运用', keywords: '', description: '' }
    },
    {
      path: '/field/:id(\\d+)',
      name: 'fieldDetail',
      component: () => import('./views/FieldDetail.vue'),
      meta: { title: '修复运用', keywords: '', description: '' }
    },
    {
      path: '/trainings/:pid(\\d+)?',
      name: 'trainings',
      component: () => import('./views/Training.vue'),
      meta: { title: '技术培训', keywords: '', description: '' }
    },
    {
      path: '/training/:id(\\d+)',
      name: 'trainingDetail',
      component: () => import('./views/TrainingDetail.vue'),
      meta: { title: '技术培训', keywords: '', description: '' }
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./views/Contact.vue'),
      meta: { title: '联系我们', keywords: '', description: '' }
    },
    {
      path: '/hr',
      name: 'hr',
      component: () => import('./views/Hr.vue'),
      meta: { title: '人才招聘', keywords: '', description: '' }
    },
    {
      path: '/message',
      name: 'message',
      component: () => import('./views/Message.vue'),
      meta: { title: '在线留言', keywords: '', description: '' }
    },
    {
      path: '/search/:keywords(\\w+)?',
      name: 'search',
      component: () => import('./views/Search.vue'),
      meta: { title: '搜索', keywords: '', description: '' }
    }
  ]
})
